import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Slider from "react-slick"
import { ArrowLeft16, ArrowRight16 } from "@carbon/icons-react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const LogoCardsBlock = ({ block }) => {
  function NextArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <ArrowRight16 />
        </span>
      </span>
    )
  }

  function PrevArrow(props) {
    const { className, onClick } = props
    return (
      <span className={className} onClick={onClick}>
        <span>
          <ArrowLeft16 />
        </span>
      </span>
    )
  }

  const settings = {
    slidesToShow: 6,
    slidesToScroll: 6,
    dots: true,
    arrows: false,
    mobileFirst: true,
    infinite: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    appendDots: (dots) => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <button page={i + 1}></button>,

    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 672,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <BlockWrapper
      className="bg-gray-50"
      block={block}
      blockWidth="container"
      showHeadline="false"
    >
      <div className="flex flex-row flex-wrap -mx-grid">
        <div className="lg:w-1/2 px-grid">
          <HeadlineEnhanced
            className="pb-4"
            kicker={block.kicker}
            headlineLevel="h2"
            headline={block.headline}
          />
        </div>
        <div className="lg:w-1/2 px-grid">
          <div className="hidden w-48 h-1 mb-12 lg:block bg-primary"></div>
          <Richtext text={block.text} />
        </div>
      </div>
      {block.logo_card_items?.length > 0 && (
        <div className="pt-8">
          <Slider className="logo-cards-slider -mx-half-grid" {...settings}>
            {block.logo_card_items.map((item, index) => (
              <div className="px-half-grid">
                {item.image?.filename?.length > 0 && (
                  <Link
                    className="relative block bg-white group"
                    link={item.link}
                  >
                    <div className="p-4 bg-white">
                      <Image
                        className="block w-full h-full"
                        image={item.image}
                        fitIn={true}
                        forceLoad={true}
                        aspectRatio="1by1"
                      />
                    </div>
                    {item.headline?.length > 0 && (
                      <div className="absolute inset-x-0 bottom-0 px-4 py-4 font-bold transition-opacity duration-500 bg-gradient-to-t from-gray-200 lg:opacity-0 lg:group-hover:opacity-100">
                        <div className="line-clamp-3">{item.headline}</div>
                      </div>
                    )}
                  </Link>
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </BlockWrapper>
  )
}

export default LogoCardsBlock
